@import "colors";

// Settings
// ==================================================
$hamburger-padding-x           : 4px;
$hamburger-padding-y           : 4px;
$hamburger-layer-width         : 20px;
$hamburger-layer-height        : 3px;
$hamburger-layer-spacing       : 4px;
$hamburger-layer-color         : $sx-green-2;
$hamburger-layer-border-radius : 4px;
$hamburger-hover-opacity       : 0.7;
$hamburger-active-layer-color  : $hamburger-layer-color;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter   : false;
$hamburger-hover-filter       : opacity(50%);
$hamburger-active-hover-filter: $hamburger-hover-filter;

@import "../../node_modules/hamburgers/_sass/hamburgers/hamburgers.scss";